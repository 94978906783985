import { Grid, Loader } from "@mantine/core";
import { useEffect } from "react";
import { useProjects } from "../../api/ProjectsContext";
import ProjectCard from "./ProjectCard";

function ProjectsList() {
  const { getProjects, projectsState } = useProjects();

  useEffect(() => {
    if (!projectsState.loading && !projectsState.data.length) {
      getProjects("projects");
    }
  }, []);

  return (
    <Grid style={{width: '100%'}}>
      {projectsState.loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : null}
        {projectsState.data.length
          ? projectsState.data.map((item) => (
            <Grid.Col md={4} sm={6} xs={12} key={item.id}>
                <ProjectCard data={item} />
                </Grid.Col>
            ))
          : null}
      </Grid>
  );
}
export default ProjectsList;
