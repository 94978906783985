import { ActionIcon,  createStyles } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEtsy,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const socialIconStyle = {
  marginRight: 50,
  marginTop: 20,
  marginBottom: 20,
};

const socialIconContainer = {
  display: "flex",
  flexDirection: "row",
  width: "100%"
};

function Socials({iconContainerStyle = socialIconContainer, iconStyle = socialIconStyle, iconSize = "2xl", iconColor = "#044484"}) {
  const socialNetworks = [
    {
      label: "Facebook",
      url: "https://www.facebook.com/bluecaboosestation",
      icon: faFacebookF,
    },
    {
      label: "Instagram",
      url: "https://www.instagram.com/bluecaboosestation/",
      icon: faInstagram,
    },
    {
      label: "Youtube",
      url: "https://www.youtube.com/@BlueCabooseVintage",
      icon: faYoutube,
    },
    {
      label: "Etsy",
      url: "https://www.etsy.com/shop/BlueCabooseStation",
      icon: faEtsy,
    },
  ];

  const navigateToSocial = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div style={iconContainerStyle}>
      {socialNetworks.map((item) => (
        <ActionIcon
          key={item.label}
          style={iconStyle}
          variant="transparent"
          onClick={() => {
            navigateToSocial(item.url);
          }}
        >
          <FontAwesomeIcon icon={item.icon} style={{ color: iconColor}} size={iconSize}/>
        </ActionIcon>
      ))}
    </div>
  );
}

export default Socials;
