import { TextInput, Button, Group, Modal, LoadingOverlay } from "@mantine/core";
import { useForm, isEmail, matches, hasLength } from "@mantine/form";
import { Grid, Title, Card, Text } from "@mantine/core";
import { useContact } from "../api/ContactContext";
import { useDisclosure } from '@mantine/hooks';
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import screenText from '../content/text'

const titleStyle = {
  fontSize: "3em",
  textAlign: "center",
  color: "#0378c1",
};

const formContainer = {
  minHeight: "50%",
};

const gridContainer = {
  height: "100%",
};

const formStyle = {
  padding: "3em",
};

const inputStyle = {
  paddingBottom: "1em",
};

const modalContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const modalTextStyle = {
  fontFamily: 'Lato',
  paddingTop: '1em'
}

function Contact() {
  const [opened, { open, close }] = useDisclosure(false);
  const { postContact, contactState } = useContact();
  const [modalText, setModalText] = useState()
  const [success, setSuccess]= useState(true)
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phone_number: ""
    },
    validate: {
      email: isEmail(screenText.contact.emailValidation),
      name: hasLength({ min: 2 }, screenText.contact.nameValidation),
      phone_number: matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
        screenText.contact.phoneNumberValidation
      ),
    },
  });

  const handleSubmit = async () => {
    form.setValues();
    const response = await postContact(form.values).then((res) => {
      setSuccess(res.type === 'SUCCESS' ? true : false)
      return res;
    });
    setModalText(response.type === 'SUCCESS' ? screenText.contact.modalSuccess : screenText.contact.modalError)
    open()
    form.reset()
  };

  const handleError = () => {
  
  };

  return (
    <Grid
      gutter={12}
      gutterXs="md"
      gutterMd="xl"
      gutterXl={50}
      style={gridContainer}
    >
      <Grid.Col span={12}>
        <Card shadow="sm" radius="md" withBorder style={formContainer}>
          <LoadingOverlay visible={contactState.loading} overlayBlur={2} />
          <Title style={titleStyle}>{screenText.contact.title}</Title>
          <Text ta="center" c="#0378c1">
            {screenText.contact.subtitle}
          </Text>
          <form
            style={formStyle}
            onSubmit={form.onSubmit(handleSubmit, handleError)}
          >
            <TextInput
              withAsterisk
              label="Name"
              placeholder="Example Name"
              {...form.getInputProps("name")}
              style={inputStyle}
            />
            <TextInput
              withAsterisk
              label="Email"
              placeholder="example@email.com"
              {...form.getInputProps("email")}
              style={inputStyle}
            />
            <TextInput
              withAsterisk
              label="Phone Number"
              placeholder="111-222-3333"
              {...form.getInputProps("phone_number")}
              style={inputStyle}
            />

            <Group position="center" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
          </form>
        </Card>
        <Modal opened={opened} onClose={close} centered>
        <div style={modalContainer}>
        <FontAwesomeIcon icon={success ? faCircleCheck : faCircleXmark} style={{ color: success ? "#4BB543" : "#cc3300", height: '3em', width: '3em'}}/>
        <Title ta="center" size="h4" style={modalTextStyle}>{modalText}</Title>
        </div>
      </Modal>
      </Grid.Col>
    </Grid>
  );
}

export default Contact;
