import { useOutlet } from "react-router-dom";
function Projects() {
  const outlet = useOutlet()
  return (
    <div>
      {outlet}
    </div>
  );
}
export default Projects;
