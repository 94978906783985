import {
  Title,
  Image,
  Loader,
  NavLink,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useNewsletter } from "../../api/NewsletterContext";
import "./Newsletter.css"

function Newsletter() {
  const { newsletterState, getNewsletters } = useNewsletter();
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (!newsletterState.loading && !newsletterState.data.length) {
      getNewsletters();
    }
  }, []);

  const setFullScreen = (url) => {
    let selectedImage;
    selectedImage = document.body?.querySelector(`img[src="${url}"]`);
    if (selectedImage) {
      if (selectedImage.requestFullScreen) {
        selectedImage.requestFullScreen();
      } else if (selectedImage.webkitRequestFullScreen) {
        selectedImage.webkitRequestFullScreen();
      } else if (selectedImage.mozRequestFullScreen) {
        selectedImage.mozRequestFullScreen();
      } else if (selectedImage.msRequestFullscreen) {
        selectedImage.msRequestFullscreen();
      } else if (selectedImage.webkitEnterFullscreen) {
        selectedImage.webkitEnterFullscreen(); //for iphone this code worked
      }
    }
  };

  const scrollToElement = (el, index) => {
    const item = document.getElementById(el);
    setActive(index);
    item.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div>
      {newsletterState.loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : null}
      {newsletterState.data?.length ? (
        <div className="container">
          <div className="navLinkContainer">
            <Title className="navLinkTitle">Publishings</Title>
            {newsletterState.data
              ? newsletterState.data.map((item, index) => (
                  <NavLink
                    key={index}
                    label={item.title}
                    onClick={() => scrollToElement(item.id, index)}
                    active={index === active}
                    classNames={{label: "navLinkStyle"}}
                  />
                ))
              : null}
          </div>
          <div className="newsletterContainer">
            {newsletterState.data
              ? newsletterState.data.map((item, index) => (
                  <div key={item.id} className="newsletterStyle" id={item.id}>
                    <Title className="newsletterTitle">{item.title}</Title>
                    <div className="newsletterImage">
                    <Image
                      src={item.thumbnail}
                      fit="contain"
                      radius="sm"
                      maw={800}
                      onClick={() => setFullScreen(item.thumbnail)}
                    />
                    </div>
                  </div>
                ))
              : null}
          </div>{" "}
        </div>
      ) : null}
    </div>
  );
}
export default Newsletter;


const navLinkStyle = {
  fontFamily: "Lato",
  fontSize: "1.5em",
};
