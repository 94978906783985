
  export const getData = async (urlPath) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}${urlPath}`;
    try {
      const setHeaders = process.env.REACT_APP_ENVIRONMENT === "dev" ? { 
        "x-api-key": process.env.REACT_APP_API_TOKEN,
        "Content-Type": "application/json",
      } : { 
        "Content-Type": "application/json",
      }
      const data = await fetch(url, {
        headers: setHeaders,
        method: "GET",
      }).then((res) => {
        if (res.status !== 200) {
          throw "GET request did not return success HTTP code.";
        }
        return res.json();
      });
      return {
        type: "SUCCESS",
        payload: data,
      };
    } catch (error) {
      return { type: "ERROR", payload: error };
    }
  };

  export const postData = async (urlPath, body) => {
    try {
      if (!body || !urlPath) {
        throw "No path or body";
      }
      const url = `${process.env.REACT_APP_API_ENDPOINT}${urlPath}`;
      const data = await fetch(url, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_TOKEN,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(body),
      }
      ).then((res) => {
        if (res.status !== 200) {
          throw "POST request did not return success HTTP code.";
        }
      });
      return {
        type: "SUCCESS",
        // payload: data,
      };
    } catch (error) {
      return { type: "ERROR", payload: error };
    }
  };