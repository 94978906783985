import { useEffect } from "react";
import { Loader, Grid, Image, Title, Text } from "@mantine/core";
import screenText from "../../content/text";
import Socials from "../../components/social/socials";
import "./About.css";
import { useAbout } from "../../api/AboutContext";

function About() {
  const { getAboutAssets, aboutState } = useAbout();
  useEffect(() => {
    if (!aboutState.loading && !aboutState.data.length) {
      getAboutAssets();
    }
  }, []);

  return (
    <div className="container">
      <Grid>
        <Grid.Col lg={6} md={6} sm={12}>
          {aboutState.loading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : null}
          <div className="imageContainer">
            {aboutState.data.length
              ? aboutState.data.map((item) => (
                    <Image placeholder key={item} src={item} maw={850} radius="md" />
                ))
              : null}
          </div>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12}>
          <Title className="aboutContainer">{screenText.about.title}</Title>
          <div className="textContainer">
            <Socials />
            <Text className="introText">{screenText.about.intro}</Text>
            {screenText.about.description.map((item) => <div key={item.header}>
              <Text className="aboutHeader">{item.header} </Text>
            <Text className="aboutText">{item.content}</Text>
              </div>)}
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default About;
