import { Card, Image, Text, Button, Title, Badge } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import screenText from '../../content/text'

function ProjectCard({ data }) {
  const navigate = useNavigate();
  const onProjectClick = () => {
    navigate(`/projects/${data.identifier}`);
  };
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      style={projectCardStyle}
    >
      <Card.Section>
        <Image src={data?.thumbnail} height={400} />
      </Card.Section>
      <div style={projectCardContent}>
      <div style={titleContainer}>
      <Badge style={data.completed ? badgeStyleComplete : badgeStyleInProgress} variant="filled" size="xl">
          {data.completed ? 'Completed' : 'In Progress'}
        </Badge>
        <Title ta="center" size="h2" weight={500}>{data?.title}</Title>
        </div>
        <div style={subtitleText}>
        <Text size="lg" color="dimmed">
          {data?.subtitle}
        </Text>
        </div>
        <div style={buttonContainer}>
        <Button
          variant="light"
          color="blue"
          fullWidth
          mt="md"
          radius="md"
          onClick={onProjectClick}
          size="lg"
        >
          {screenText.projectCard.buttonText}
        </Button>
        </div>
      </div>
    </Card>
  );
}
export default ProjectCard;

const projectCardStyle = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
};

const projectCardContent = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1
}

const subtitleText = {
  paddingTop: '0.5em'
}

const buttonContainer = {
  display: 'flex',
  flex: 1,
  alignItems: 'flex-end'
}

const titleContainer = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '1em'
}

const badgeStyleComplete = {
  backgroundColor: "rgba(75, 158, 43, 0.75)",
  marginBottom: '1em'

}

const badgeStyleInProgress = {
 backgroundColor: "rgba(245, 139, 47, 0.75)",
 marginBottom: '1em'
}
