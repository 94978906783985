import { Carousel } from "@mantine/carousel";
import { Image } from "@mantine/core";

function CarouselLayout({ data, slideSize = "25%", height=500, align="start", mdSlideSize="50%", smSlideSize = "100%", lgSlideSize="50%"}) {


  const setFullScreen = (url) => {
    let selectedImage;
    selectedImage = document.body?.querySelector(`img[src="${url}"]`)
    if (selectedImage) {
      if (selectedImage.requestFullScreen) {
        selectedImage.requestFullScreen();
      } else if (selectedImage.webkitRequestFullScreen) {
        selectedImage.webkitRequestFullScreen();
      } else if (selectedImage.mozRequestFullScreen) {
        selectedImage.mozRequestFullScreen();
      } else if (selectedImage.msRequestFullscreen) {
        selectedImage.msRequestFullscreen();
      } else if (selectedImage.webkitEnterFullscreen) {
        selectedImage.webkitEnterFullscreen(); //for iphone this code worked
      }
    }
  }
  return (
    <Carousel
      controlSize={40}
      align={align}
      slideSize={slideSize}
      height={height}
      slideGap="sm"
      controlsOffset="xs"
      inViewThreshold={1}
      loop
      breakpoints={[
        { maxWidth: "lg", slideSize: lgSlideSize },
        { maxWidth: "md", slideSize: mdSlideSize },
        { maxWidth: "sm", slideSize: smSlideSize, slideGap: 0 },
      ]}
    >
      {data.map((url) =>
        url ? (
          <Carousel.Slide key={url}>
            <Image height={height} src={url} />
          </Carousel.Slide>
        ) : null
      )}
    </Carousel>
  );
}

export default CarouselLayout;