import React, { createContext, useContext } from "react";
import { getData } from "./Api";

const data = {
  loading: false,
  error: false,
  data: [],
  images : null
};

const projectsReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: true, error: false };
    case "SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
      case "SUCCESS_IMAGES":
        return {
          ...state,
          loading: false,
          error: false,
          images: action.payload,
        };
    case "ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };
    case "CLEAR_IMAGES": 
      return {
        ...state,
        images: []
      }
    default:
      return state;
  }
};

const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const [projectsState, dispatch] = React.useReducer(projectsReducer, data);

  const getProjects = async (filter) => {
    dispatch({ type: "LOADING" });
    try {
      const urlPath = `/assets?filter=${filter}`;
      await getData(urlPath).then((data) => {
        if (data?.type === "ERROR") { 
            return dispatch({
            type: "ERROR",
            payload: data.payload
        })}
        return dispatch({
          type: "SUCCESS",
          payload: data.payload?.length ? sortProjects(data.payload) : data.payload
        });
      });
    } catch (error) {
      return dispatch({
        type: "ERROR",
        payload: error,
      });
    }
  };


  const getProjectImages =  async (filter) => {
    if(projectsState.images && projectsState.images?.images && (filter === projectsState.images?.id)){return}
    dispatch({ type: "CLEAR_IMAGES"})
    dispatch({ type: "LOADING" });
    try {
      const urlPath = `/images?type=projects/${filter}/images`;
      await getData(urlPath).then((data) => {
        if (data?.type === "ERROR") { 
            return dispatch({
            type: "ERROR",
            payload: data.payload
        })}
        return dispatch({
          type: "SUCCESS_IMAGES",
          payload: {
            id: filter,
            images: data.payload.urls
          }
        });
      });
    } catch (error) {
      return dispatch({
        type: "ERROR",
        payload: error,
      });
    }
  };

  const sortProjects = (data) => {
    const sortedProjects = data.sort((a, b) => {
      // Sort by completed field
      if (a.completed !== b.completed) {
        return a.completed ? 1 : -1;
      }

      // Sort by created date
      const createdDateComparison = new Date(b.created) - new Date(a.created);
      if (createdDateComparison !== 0) {
        return createdDateComparison;
      }
      return 0;
    });

    return sortedProjects;
  };
  return (
    <ProjectsContext.Provider value={{ getProjects, projectsState, getProjectImages }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => {
  const context = useContext(ProjectsContext);

  if (!context) {
    return new Error("Provider required");
  }
  return context;
};
