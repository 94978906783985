import { Grid, Loader, AspectRatio, Image } from "@mantine/core";
import { useEffect } from "react";
import { useGallery } from "../api/GalleryContext";
function Gallery() {
  const { getGalleryAssets, galleryState } = useGallery();

  useEffect(() => {
    if (!galleryState.loading && !galleryState.data.length) {
      getGalleryAssets();
    }
  }, []);

  const setFullScreen = (url) => {
    let selectedImage;
    selectedImage = document.body?.querySelector(`img[src="${url}"]`);
    if (selectedImage) {
      if (selectedImage.requestFullScreen) {
        selectedImage.requestFullScreen();
      } else if (selectedImage.webkitRequestFullScreen) {
        selectedImage.webkitRequestFullScreen();
      } else if (selectedImage.mozRequestFullScreen) {
        selectedImage.mozRequestFullScreen();
      } else if (selectedImage.msRequestFullscreen) {
        selectedImage.msRequestFullscreen();
      } else if (selectedImage.webkitEnterFullscreen) {
        selectedImage.webkitEnterFullscreen(); //for iphone this code worked
      }
    }
  };

  return (
    <Grid>
      {galleryState.loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : null}
      {galleryState.data
        ? galleryState.data.map((url, index) => (
            <Grid.Col
              key={url}
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={imageContainer}
              onClick={() => setFullScreen(url)}
            >
              <AspectRatio ratio={4 / 3}>
                <Image src={url} fit="contain" radius="sm" />
              </AspectRatio>
            </Grid.Col>
          ))
        : null}
    </Grid>
  );
}

export default Gallery;

const imageContainer = {
  cursor: "pointer",
};
