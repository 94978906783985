import { useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link } from "react-router-dom";
const HEADER_HEIGHT = rem(150);

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    maxWidth: "80rem",
    fontFamily: 'Lato'
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.xl,
    fontWeight: 800,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

const links = [
  {
    label: "Home",
    link: "/",
    protected: false,
    target: "",
  },
  {
    label: "Online Store",
    link: "https://www.etsy.com/shop/BlueCabooseStation",
    protected: false,
    target: "_blank",
  },
  {
    label: "Projects",
    link: "/projects",
    protected: false,
    target: "",
  },
  {
    label: "Gallery",
    link: "/gallery",
    protected: false,
    target: "",
  },
    {
    label: "Newsletter",
    link: "/newsletter",
    protected: false,
    target: ""

  },
  {
    label: "About Us",
    link: "/about",
    protected: false,
    target: "",
  },
  {
    label: "Contact Us",
    link: "/contact",
    protected: false,
    target: "",
  },
];

function HeaderNav() {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();

  return (
    <Header height={HEADER_HEIGHT} mb={0} className={classes.root}>
      <Container className={classes.header}>
        <Image
          maw={300}
          src={require("../assets/blue_caboose_logo_square.png")}
        />
        <Group spacing={5} className={classes.links}>
          {links.map((item) => //add protected and auth state logic here
              <Link
                key={item.label}
                to={item.link}
                target={item.target}
                className={cx(classes.link, {
                  [classes.linkActive]: active === item.link,
                })}
                onClick={(event) => {
                  setActive(item.link);
                  close();
                }}
              >
                {item.label}
              </Link>
            )} 
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {links.map((item) => //add protected and auth state logic here
                  <Link
                    key={item.label}
                    to={item.link}
                    target={item.target}
                    className={cx(classes.link, {
                      [classes.linkActive]: active === item.link,
                    })}
                    onClick={(event) => {
                      setActive(item.link);
                      close();
                    }}
                  >
                    {item.label}
                  </Link>
                )}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}

export default HeaderNav;