import CarouselLayout from "../../components/carousel/CarouselLayout";
import { useEffect } from "react";
import { Loader, Text, Title, Grid, Image } from "@mantine/core";
import { useCarousel } from "../../api/CarouselContext";
import screenText from "../../content/text";
import "./Home.css";
import Train from "../../components/train/train";
import { useHome } from "../../api/HomeContext";
import Socials from "../../components/social/socials";

const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
};

const carouselLoaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 500,
  width: "100%",
};


function Home() {
  const { getCarouselAssets, carouselState } = useCarousel();
  const { getHomeAssets, homeState } = useHome();
  useEffect(() => {
    if (!carouselState.loading && !carouselState.data.length) {
      getCarouselAssets();
    }
    if (!homeState.loading && !homeState.data.length) {
      getHomeAssets();
    }
  }, []);

  return (
    <div>
      <div>
        <Title className="titleContainer">{screenText.home.title}</Title>
        <Text className="subTitleHeader" ta="center" fs="italic">
          {screenText.home.subtitle}
        </Text>
        {carouselState.loading && (
          <div style={carouselLoaderStyle}>
            <Loader />
          </div>
        )}
        {carouselState?.data.length ? (
          <CarouselLayout data={carouselState.data} />
        ) : null}
        <div className="trainContainer">
          <Train />
        </div>
        <Grid className="descriptionContainer">
          <Grid.Col md={6} sm={12} xs={12}>
            {homeState.loading && (
              <div style={loaderStyle}>
                <Loader />
              </div>
            )}
            {homeState?.data.length ? (
              <Image maw={800} src={homeState.data[0]} radius="sm" />
            ) : null}
          </Grid.Col>
          <Grid.Col md={6} sm={12} xs={12}>
            <Title className="descriptionTitle">
              {screenText.home.descriptionTitle}
            </Title>
            <Text className="descriptionText">
              {screenText.home.description}
            </Text>
            <Text className="socialMediaText">
              {screenText.home.socialMediaText}
            </Text>
            <Socials/>
          </Grid.Col>
        </Grid>
        {/* </div> */}
        {/* <Text>{screenText.home.newsletter}</Text> will add this in when I have time*/}
      </div>
    </div>
  );
}

export default Home;
