import React, { createContext, useContext } from "react";
import { getData } from "./Api";

const data = {
  loading: false,
  error: false,
  data: [],
  images: null,
};

const newsletterReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: true, error: false };
    case "SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case "SUCCESS_IMAGES":
      return {
        ...state,
        loading: false,
        error: false,
        images: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };
    case "CLEAR_IMAGES":
      return {
        ...state,
        images: [],
      };
    default:
      return state;
  }
};

const NewsletterContext = createContext();

export const NewsletterProvider = ({ children }) => {
  const [newsletterState, dispatch] = React.useReducer(newsletterReducer, data);

  const getNewsletters = async (filter) => {
    dispatch({ type: "LOADING" });
    try {
      const urlPath = `/assets?filter=newsletters`;
      await getData(urlPath).then((data) => {
        if (data?.type === "ERROR") {
          return dispatch({
            type: "ERROR",
            payload: data.payload,
          });
        }
        const sortedResults = data.payload.length
          ? sortResults(data.payload)
          : data.payload;
        return dispatch({
          type: "SUCCESS",
          payload: sortedResults,
        });
      });
    } catch (error) {
      return dispatch({
        type: "ERROR",
        payload: error,
      });
    }
  };

  const sortResults = (data) => {
    const today = new Date();
    const sortedData = data.sort((a, b) => {
      const dateA = Math.abs(today - new Date(a.uploadDate));
      const dateB = Math.abs(today - new Date(b.uploadDate));
      return dateA - dateB;
    });
    return sortedData;
  };
  return (
    <NewsletterContext.Provider value={{ getNewsletters, newsletterState }}>
      {children}
    </NewsletterContext.Provider>
  );
};

export const useNewsletter = () => {
  const context = useContext(NewsletterContext);

  if (!context) {
    return new Error("Provider required");
  }
  return context;
};
