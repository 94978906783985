import { MantineProvider, AppShell } from "@mantine/core";
import HeaderNav from "./components/header/headerNav";
import {
  useOutlet,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import "./App.css";
import { CarouselProvider } from "./api/CarouselContext";
import { AboutProvider } from "./api/AboutContext";
import { ProjectsProvider } from "./api/ProjectsContext";
import { ContactProvider } from "./api/ContactContext";
import { HomeProvider } from "./api/HomeContext";
import { GalleryProvider } from "./api/GalleryContext";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Project from "./pages/Projects/Project";
import ProjectsList from "./pages/Projects/ProjectsList";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Newsletter from "./pages/Newsletter/Newsletter";
import { NewsletterProvider } from "./api/NewsletterContext";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <HomeProvider>
      <CarouselProvider>
        <GalleryProvider>
          <AboutProvider>
            <ProjectsProvider>
              <ContactProvider>
                <NewsletterProvider>
                  <Router>
                    <MantineProvider
                      withGlobalStyles
                      withNormalizeCSS
                      theme={{ fontFamily: "eloquent-jf-pro" }}
                    >
                      <AppShell padding="xl" header={<HeaderNav />} footer={<Footer/>}>
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/about" element={<About />} />
                          <Route path="/contact" element={<Contact />} />
                          <Route path="/gallery" element={<Gallery />} />
                          <Route path="/projects" element={<Projects />}>
                            <Route index element={<ProjectsList />} />
                            <Route path=":project" element={<Project />} />
                          </Route>
                          <Route path="/newsletter" element={<Newsletter/>}/>
                        </Routes>
                      </AppShell>
                    </MantineProvider>
                  </Router>
                  </NewsletterProvider>
              </ContactProvider>
            </ProjectsProvider>
          </AboutProvider>
        </GalleryProvider>
      </CarouselProvider>
    </HomeProvider>
  );
}

export default App;