import React, { createContext, useContext } from "react";
import { postData } from "./Api";

const data = {
  loading: false,
  error: false,
  data: [],
};

const contactReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: true, error: false };
    case "SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
  const [contactState, dispatch] = React.useReducer(contactReducer, data);

  const postContact = async (data) => {
    const urlPath = "/email";
    const body = data;
    dispatch({ type: "LOADING" });
    try {
      if (!body.name || !body.phone_number || !body.email) {
        throw "PostEmail is missing required parameters";
      }
      await postData(urlPath, body).then((data) => {
        if (data?.type === "ERROR") {
          throw data.payload;
        }
        dispatch({
          type: "SUCCESS",
          payload: data.payload,
        });
      });
      return { type: "SUCCESS" };
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: error,
      });
      return { type: "ERROR" };
    }
  };

  return (
    <ContactContext.Provider value={{ postContact, contactState }}>
      {children}
    </ContactContext.Provider>
  );
};

export const useContact = () => {
  const context = useContext(ContactContext);

  if (!context) {
    return new Error("Provider required");
  }
  return context;
};
