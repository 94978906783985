import React, { createContext, useContext } from "react";
import { getData } from "./Api";

const data = {
  loading: false,
  error: false,
  data: [],
};

const aboutReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: true, error: false };
    case "SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

const AboutContext = createContext();

export const AboutProvider = ({ children }) => {
  const [aboutState, dispatch] = React.useReducer(aboutReducer, data);

  const getAboutAssets = async () => {
    dispatch({ type: "LOADING" });
    try {
      const urlPath = `/images?type=about/`;
      await getData(urlPath).then((data) => {
        if (data?.type === "ERROR") { 
            return dispatch({
            type: "ERROR",
            payload: data.payload
        })}
        return dispatch({
          type: "SUCCESS",
          payload: data.payload?.urls
        });
      });
    } catch (error) {
      return dispatch({
        type: "ERROR",
        payload: error,
      });
    }
  };

  return (
    <AboutContext.Provider value={{ getAboutAssets, aboutState }}>
      {children}
    </AboutContext.Provider>
  );
};

export const useAbout = () => {
  const context = useContext(AboutContext);

  if (!context) {
    return new Error("Provider required");
  }
  return context;
};
